<template>
  <v-container>
    <v-dialog
      v-model="!$store.getters['user/isVerified']"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title
          class="text-h5 grey--text text--darken-2 yellow lighten-3 pa-4"
        >
          Welcome to Your Profile!
        </v-card-title>

        <v-card-text class="pa-4 text-h6">
          Please wait until your account is verified.<br />
          or contact our support to activate.<br />
          Phone:<a href="tel:+8809638011099">+88 09638011099</a><br />
          Email:<a href="mailto:support@meetingme.live"
            >support@meetingme.live</a
          ><br />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="primary" to="/logout"> Logout </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" md="8">
        <schedules></schedules>
      </v-col>
      <v-col cols="12" md="4">
        <v-row>
          <v-col>
            <user-menu></user-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <upcoming-meetings></upcoming-meetings>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4">
      <v-col cols="12"> <RoomList></RoomList> </v-col>
    </v-row>

    <!-- <v-row>
      <v-col> </v-col>
    </v-row>
    <v-row><v-col></v-col></v-row> -->
    <!-- <v-row class="p-2"><user-card></user-card> </v-row> -->
    <!-- <v-row class="mt-4">
      <v-col>
        <v-row>
          <v-col> </v-col>
        </v-row>
        <v-row>
          <v-col> </v-col>
        </v-row>
      </v-col>

      <v-col>
        <v-row>
          <v-col>
            <user-tabs></user-tabs>
          </v-col>
        </v-row>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import RoomList from "@/components/room/RoomList.vue";
import UpcomingMeetings from "@/components/profile/UpcomingMeetings.vue";
import Schedules from "@/components/profile/Schedules.vue";
import UserMenu from "../../components/profile/UserMenu.vue";
export default {
  components: {
    RoomList,
    UpcomingMeetings,
    Schedules,
    UserMenu,
  },
  data() {
    return {};
  },
  created() {},
};
</script>

<style lang="sass"></style>

