<template>
  <v-card>
    <v-toolbar color="secondary" flat dark>
      <v-toolbar-title
        v-html="$store.getters['user/getName']"
      ></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-cog-outline</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item text link to="/profile/update">
            <v-icon class="px-2" color="accent">mdi-account</v-icon> Update
            Profile Information
          </v-list-item>
          <v-list-item
            v-if="$store.getters['user/isAdmin']"
            text
            link
            to="/organization/manage/"
          >
            <v-icon class="px-2" color="accent">mdi-file-cog</v-icon> Manage
            Organization
          </v-list-item>
          <v-list-item
            v-if="$store.getters['user/isTeacher']"
            text
            link
            to="/teacher/manage/"
          >
            <v-icon class="px-2" color="accent">mdi-file-cog</v-icon>Tutor Admin
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
  </v-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dialogShowTeacherBooking: false,
      dialogCreateListingProfile: false,
      dialogAssignUsersToRooms: false,
    };
  },
  methods: {
    closeDialog(dialog) {
      this[dialog] = false;
    },
  },
};
</script>

<style>
</style>
