<template>
  <v-card color="grey lighten-4" outlined class="mt-4" min-height="100">
    <h3>{{ rooms.room_subject }}</h3>
    <v-toolbar width="100%" dark color="third">
      <v-toolbar-title>My Rooms</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog v-model="dialogJoinClass" persistent width="400px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined v-bind="attrs" v-on="on" class="pa-2 mr-2"
            >Join Room</v-btn
          >
        </template>
        <JoinRoom @closeDialog="closeDialogJoinClass"> </JoinRoom>
      </v-dialog>
      <!-- <v-dialog
        v-if="$store.getters['user/isAdmin']"
        v-model="dialog_new_class"
        persistent
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined v-bind="attrs" v-on="on" class="pa-2"
            >New Room
          </v-btn>
        </template>
        <NewRoom @closeDialog="new_class_close_dialog"> </NewRoom>
      </v-dialog> -->
    </v-toolbar>
    <!-- <v-row>
      <v-col cols="12" v-for="room in rooms" :key="room.id">
        <v-card outlined class="pa-4" color="gray-lighten-3" :loading="loading">
          <v-card-title>{{ room.name }}</v-card-title>
          <v-card-subtitle
            >{{ room.code }}
            <v-btn icon v-on:click.prevent="copyText(room.code)">
              <v-icon small> mdi-content-copy </v-icon>
            </v-btn>
          </v-card-subtitle>
          <v-divider class="my-2"></v-divider>
          <v-row>
            <v-col
              v-for="cls in room.room_subjects"
              v-bind:key="cls.id"
              cols="12"
              md="6"
              lg="3"
            >
              <v-card
                class="pa-2"
                width="100%"
                elevation="3"
                outlined
                height="150"
                :to="{ name: 'room', params: { id: cls.id } }"
              >
                <v-card-title
                  >{{ cls.subject.title }}
                  <v-chip
                    class="mx-1"
                    small
                    color="#ff6666"
                    dark
                    v-if="cls.is_teacher"
                    >Teacher</v-chip
                  >
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row> -->

    <v-row>
      <v-col cols="12" v-for="room in rooms" :key="room.room_id">
        <v-card outlined class="pa-4" color="gray-lighten-3" :loading="loading">
          <v-card-title>{{ room.name }}</v-card-title>
          <v-card-subtitle
            >{{ room.code }}
            <v-btn icon @click.prevent="copyText(room.code)">
              <v-icon small>mdi-content-copy</v-icon>
            </v-btn>
          </v-card-subtitle>
          <v-divider class="my-2"></v-divider>
          <v-row>
            <v-col
              v-for="cls in room.room_subjects"
              :key="cls.id"
              cols="12"
              md="6"
              lg="3"
            >
              <v-card
                class="pa-2"
                width="100%"
                elevation="3"
                outlined
                height="150"
                :to="{ name: 'room', params: { id: cls.id } }"
              >
                <v-card-title
                  >{{ cls.title }}
                  <v-chip
                    class="mx-1"
                    small
                    color="#ff6666"
                    dark
                    v-if="cls.is_teacher"
                    >Teacher</v-chip
                  >
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="showCopied" timeout="1000">Copied</v-snackbar>
  </v-card>
</template>

<script>
export default {
  components: {
    JoinRoom: () => import("@/components/room/JoinRoom.vue"),
  },
  data: () => ({
    rooms: [],
    room: "",
    loading: false,
    showCopied: false,
    dialogJoinClass: false,
  }),
  async mounted() {
    await this.getRooms();
  },
  methods: {
    // getRooms() {
    //   this.loading = true;
    //   return this.$api
    //     .get("/rooms/")
    //     .then((res) => {
    //       this.rooms = res.data.results;
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },
    // amended
    getRooms() {
      this.loading = true;
      return this.$api
        .get("/classrooms/")
        .then((res) => {
          this.rooms = res.data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeDialogJoinClass: function () {
      this.dialogJoinClass = false;
    },
    copyText(text) {
      if (text) {
        navigator.clipboard.writeText(text);
        this.showCopied = true;
      }
    },
  },
};
</script>

<style>
</style>